import React from "react"
import Janowo from "./osiedle-janowo-park/index"
import Helmet from "react-helmet"

const OfferIndexPage = () => (
  <>
    <Helmet>
      <title>WILLBUD | Osiedle Gdynia Wiczlino II</title>
    </Helmet>
    <Janowo />
  </>
)

export default OfferIndexPage
